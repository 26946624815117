<template>
  <div class="page">
    <div class="container">
      <div class="header">
        <el-image :src="require('@/assets/LOGO.svg')" @click="toHome" style="cursor: pointer;width: 155px; height: 21px;"></el-image>
        <div class="header__content">
          <a href="mailto:exam@holowits.com" class="contact">Contact Us</a>
        </div>
      </div>
      <div class="container__body">
        <template v-if="!showSuccess">
          <h1 class="login_title">Reset Password</h1>
          <el-form :model="loginForm" class="login_form" :rules="rules" ref="loginForm">
            <el-form-item prop="email">
              <el-input placeholder="Email" v-model="loginForm.email"/>
            </el-form-item>
            <el-form-item prop="password">
              <div class="flex-content">
                <el-input placeholder="Verification Code" v-model="loginForm.code"/>
                <div class="verification-code-image" @click="getCodeImage">
                  <img :src="imageCode" alt="">
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div class="flex-btn-group">
            <el-button type="primary" class="submit_btn" plain @click="cancelSubmit">Cancel</el-button>
            <el-button type="primary" class="submit_btn" @click="submitForget">Submit</el-button>
          </div>
        </template>
        <template v-if="showSuccess">
          <div class="success-panel">
            <img class="success-panel-icon" src="../../assets/image/login/icon_success.png" alt="success">
            <div class="success-panel-title">Submitted successfully</div>
            <p class="success-panel-text">A confirmation email will be sent to your mailbox.Please
              check your email and confirm within 1 hours.</p>
            <el-button type="primary" @click="back">Back</el-button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {sendEmail, getCaptcha} from '@/api/login';
  import {randomNum} from '@/utils/commons';

  export default {
    name: 'index',
    data() {
      return {
        loading: false,
        loginForm: {
          email: '',
          code: '',
          key: randomNum(24, 16),
        },
        imageCode: '',
        rules: {
          email: [{required: true, message: 'Email cannot be empty.', trigger: 'blur'}],
          code: [{required: true, message: 'Password cannot be empty.', trigger: 'blur'}],
        },
        showSuccess:false,
      };
    },
    mounted() {
      this.getCodeImage();
    },
    methods: {
      toHome() {
        this.$router.replace({name: 'home'});
      },

      getCodeImage() {
        getCaptcha(this.loginForm.key).then(response => {
          const res = response.data;
          return ('data:image/png;base64,' + btoa(new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '',),)
          );
        }).then(res => {
          this.imageCode = res;
        }).catch(e => {
          if (e.toString().indexOf('429') !== -1) {
            this.$message({
              message: this.$t('tipInfo.tooManyRequest'),
              type: 'error',
            });
          } else {
            this.$message({
              message: this.$t('tipInfo.getCodeImageFailed'),
              type: 'error',
            });
          }
        });
      },

      submitForget() {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.loading = true;
            sendEmail(this.loginForm).then(res => {
              const response = res.data;
              if (response.isSuccess) {
                this.showSuccess = true
              }
            });
          } else {
            return false;
          }
        });
      },
      cancelSubmit(){
        this.$refs.loginForm.clearValidate()
        this.$refs.loginForm.resetFields()
        this.$router.push('/login');
      },
      back() {
        this.$router.push("/")
      },
    },
  };
</script>

<style scoped lang="less">
  @import "login";
</style>
<style scoped lang="scss">
  .cus-popper {
    transform: translateY(-8px);
  }
</style>
